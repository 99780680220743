<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Credit Card Activity Logs</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching">
          <ErrorCard v-if="errorFetching" message="Admin Access Logs Not Available"/>
        </div>
        <div v-else>
          <div class="w-full">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                :totalRows="verifoneActivityLogs.totalElements"
                :isLoading="isLoading"
                max-height="750px"
                theme="polar-bear"
                :sort-options="{
                enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: this.serverParams.size,
                  perPageDropdownEnabled: false,
                  dropdownAllowAll: false,
                  setCurrentPage: verifoneActivityLogs.number + 1,
                }"
                :rows="verifoneActivityLogs.content"
                :columns="columns"
                styleClass="vgt-table bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'details'" class="flex justify-center">
                  <button v-if="!isRegistrationLog(props.row.event)" @click="onDetailClick(props.row)">
                    <FontAwesomeIcon :icon="['far', 'plus']" class="text-orange" size="2xl" />
                  </button>
                </span>
                <span v-else :class="[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-md font-medium']">
                  {{props.formattedRow[props.column.field] || "-"}}
                </span>
              </template>
              <template slot="table-column" slot-scope="props">
                 <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block']">
                {{props.column.label}}
                 </span>
              </template>
              <template slot="loadingContent">
                <LoadingCard/>
              </template>
              <div slot="emptystate">
                No Printer Logs to show
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import LoadingCard from "@/components/Helpers/Loading/LoadingCard";
import PageTemplate from "@/components/Helpers/Pages/PageTemplate";
import ErrorCard from "@/components/Helpers/Pages/ErrorCard";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import {mapActions, mapState, mapMutations} from "vuex";
import {VueGoodTable} from "vue-good-table";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

const VERIFONE_REGISTRATION_TAGS = ["Registered", "Unregistered"];

export default {
  name: "VerifoneActivityLog",
  mixins: [LocalLogFormatters],
  components: {
    VueGoodTable,
    LoadingCard,
    PageTemplate,
    ErrorCard,
    BasicNav,
    FontAwesomeIcon
  },
  data() {
    return {
      isLoading: false,
      errorFetching: false,
      columns: [
        {
          label: "Event",
          field: "event",
          formatFn: this.formatLogType,
          sortable: false,
        },
        {
          label: "Status",
          field: "status",
          formatFn: this.capitalizeWords,
          sortable: false,
        },
        {
          label: "User",
          field: "username",
          formatFn: this.formatLogUser,
          sortable: false,
        },
        {
          label: "Date/Time",
          field: "createDateTime",
          sortable: false,
          formatFn: this.formatLogDate,
        },
        {
          label: "Confirmation",
          field: "confirmation",
          sortable: false,
        },
        {
          label: "Card Type",
          field: "cardType",
          formatFn: this.capitalizeWords,
          sortable: false,
        },
        {
          label: "Details",
          field: "details"
        }
      ],
    };
  },
  methods: {
    ...mapActions("CreditCardMachine", ["getVerifoneActivityLogs"]),
    ...mapMutations("CreditCardMachine", ["updateParams"]),
    async loadRows() {
      try {
        this.isLoading = true;
        await this.getVerifoneActivityLogs();
      } catch (e) {
        this.errorFetching = true;
        this.$toasted.error("Fetching verifone activity logs failed.");
      } finally {
        this.isLoading = false;
      }
    },
    async onPageChange(params) {
      this.updateParams({page: params.currentPage - 1});
      await this.loadRows();
    },
    onDetailClick(row) {
      this.$router.push({
        name: "VerifoneDetails",
        params: { id: row.recordId },
      });
    },
    isRegistrationLog(productType) {
      return VERIFONE_REGISTRATION_TAGS.includes(productType)
    },
  },
  computed: {
    ...mapState("CreditCardMachine", ["verifoneActivityLogs", "serverParams"]),
  },
  async mounted() {
    await this.loadRows();
  }
}
</script>

<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18) !important;
}
</style>
