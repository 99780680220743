var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',[_c('template',{slot:"nav"},[_c('BasicNav',[_c('template',{slot:"nav-center"},[_c('div',{staticClass:"flex justify-center"},[_c('h1',{staticClass:"text-3xl"},[_vm._v("Credit Card Activity Logs")])])])],2)],1),_c('template',{slot:"body"},[_c('div',{staticClass:"grid grid-cols-1 mb-8"},[(_vm.errorFetching)?_c('div',{staticClass:"m-auto"},[(_vm.errorFetching)?_c('ErrorCard',{attrs:{"message":"Admin Access Logs Not Available"}}):_vm._e()],1):_c('div',[_c('div',{staticClass:"w-full"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.verifoneActivityLogs.totalElements,"isLoading":_vm.isLoading,"max-height":"750px","theme":"polar-bear","sort-options":{
              enabled: false,
              },"pagination-options":{
                enabled: true,
                perPage: this.serverParams.size,
                perPageDropdownEnabled: false,
                dropdownAllowAll: false,
                setCurrentPage: _vm.verifoneActivityLogs.number + 1,
              },"rows":_vm.verifoneActivityLogs.content,"columns":_vm.columns,"styleClass":"vgt-table bordered"},on:{"on-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'details')?_c('span',{staticClass:"flex justify-center"},[(!_vm.isRegistrationLog(props.row.event))?_c('button',{on:{"click":function($event){return _vm.onDetailClick(props.row)}}},[_c('FontAwesomeIcon',{staticClass:"text-orange",attrs:{"icon":['far', 'plus'],"size":"2xl"}})],1):_vm._e()]):_c('span',{class:[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-md font-medium']},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] || "-")+" ")])]}},{key:"table-column",fn:function(props){return [_c('span',{class:[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block']},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('LoadingCard')],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No Printer Logs to show ")])],2)],1)])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }